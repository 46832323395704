import { post } from '@/http/http'
// 员工查询
export const staffGet = p => post('api/v1.0/staffinfo/staff_info_get', p)

// 员工添加
export const staffAdd = p => post('api/v1.0/staffinfo/staff_info_add', p)

// 员工编辑
export const staffUpdate = p => post('api/v1.0/staffinfo/staff_info_update', p)

// 员工删除
export const staffDelete = p => post('api/v1.0/staffinfo/staff_info_delete', p)

// 员工停用/启用
export const staffEnable = p => post('api/v1.0/staffinfo/staff_enable', p)

// 员工修改密码
export const staffPs = p => post('api/v1.0/staffinfo/staff_ps', p)

// 员工查询(id)
export const staffInfoGet = p => post('api/v1.0/staffinfo/staff_info_get_id', p)

// 员工头像编辑
export const staffImgUpdate = p => post('api/v1.0/staffinfo/staff_info_update_image', p)

// 员工重置密码
export const staffPwdReset = p => post('api/v1.0/staffinfo/staff_reset', p)
