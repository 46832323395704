<template>
  <!-- 个人信息 -->
  <div class="box100">
    <div class="userForm">
      <el-upload
        class="avter"
        :action="action"
        :headers="headers"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-success="handleAvatarSuccess">
        <div class="dyig">修改头像</div>
        <img v-if="userInfo.furl == null" :src="imgUrl" class="avatar">
        <img v-if="userInfo.furl != null" :src="userInfo.furl" class="avatar">
      </el-upload>
      <div class="show">
        <p class="usertit flexCB"><span class="userlf">姓名</span><span class="usertf">{{userInfo.sname}}</span></p>
        <p class="usertit flexCB">
          <span class="userlf">性别</span>
          <span class="usertf" v-if="userInfo.ssex == 1">男</span>
          <span class="usertf" v-if="userInfo.ssex == 2">女</span>
        </p>
        <p class="usertit flexCB"><span class="userlf">手机号码</span><span class="usertf">{{userInfo.sphone}}</span></p>
        <p class="usertit flexCB"><span class="userlf">部门</span><span class="usertf">{{userInfo.depname}}</span></p>
        <p class="usertit flexCB"><span class="userlf">岗位</span><span class="usertf">{{userInfo.sysvalue}}</span></p>
        <p class="usertit flexCB"><span class="userlf">角色</span><span class="usertf">{{userInfo.rolename}}</span></p>
      </div>
    </div>
    
  </div>
</template>

<script>
import { staffInfoGet, staffImgUpdate } from '@/api/staff.js'
import Upload from '@/components/upload.vue'
export default {
  components: {
    Upload
  },
  data () {
    return {
      action: this.BASE_URL + this.$url.PATH.annex,
      imgUrl: require('@/assets/img/users.png'),
      userInfo: {},
      showImg: false,
      simage: '',
      avteImg: ''
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        loginway: 'pc',
        sign: this.$url.PATH.sign
      }
    }
  },
  created () {
    this.staffid = JSON.parse(window.localStorage.getItem('user')).staffid
    this.init()
  },
  methods: {
    // 个人信息查询
    init () {
      staffInfoGet({staffid: this.staffid}).then(res => {
        if (res.status === 1) {
          this.userInfo = res.data.data[0]
        } else {
          this.userInfo = {}
        }
      })
    },
    // 更换头像
    changAnter () {
      staffImgUpdate({staffid: this.staffid, simage: this.simage}).then(res => {
        if (res.status === 1) {
          this.userInfo.furl = this.avteImg
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    beforeAvatarUpload (file) {
      const type = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const isJPG = this.fileType.includes(type)
      const isJPG = 'jpg/png/jpeg'.includes(type)
      const isLt50M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('请上传支持的图片类型文件')
        return false
      } else if (!isLt50M) {
        this.$message.error('上传图片大小不能超过 5MB!')
        return false
      } else {
        this.loading = true
        return isJPG && isLt50M
      }
    },
    handleAvatarSuccess (res) {
      if (res.status === 1) {
        this.simage = res.data.fid
        this.avteImg = res.data.furl
        this.changAnter()
      } else {
        this.$message.error(res.message)
      }
    },
  }
}
</script>

<style scoped>
.userForm {
  width: 40%;
  min-width: 600px;
  height: 100%;
  margin: 0 auto;
  background: #fff;
  position: relative;
}
.avter {
  width: 140px;
  height: 140px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
}
.avatar {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
}
.dyig {
  position: absolute;
  top: 50%;
  left: 30%;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  z-index: 999;
}
.show {
  padding: 20px 50px;
  box-sizing: border-box;
}
.usertit {
  border-bottom: 1px solid #F3F4F9;
  line-height: 80px;
}
.userlf {
  font-size: 16px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #A5B7CC;
}
.usertf {
  font-size: 16px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #333333;
}
</style>
